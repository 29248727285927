import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/secondary_pages.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'


import img1 from "../images/contact-us-pic.jpg"

const phoneRegex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

const emailRegex =
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export default class ContactPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formValid: false,
      txtFullName: "",
      txtFullNamePassed: false,
      txtFullNameValid: false,
      txtEmail: "",
      txtEmailPassed: false,
      txtEmailValid: false,
      txtPhone: "",
      txtPhonePassed: false,
      txtPhoneValid: true,
      txtMessage: "",
      txtMessagePassed: false,
      txtMessageValid: false
    }

    this.handleFullNameChange = this.handleFullNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.isFormValid = this.isFormValid.bind(this);

    this.state.formValid = this.isFormValid();
  }

  handleFullNameChange(event) {
    const target = event.target;
    const value = target.value;
    const valid = value.length > 0;

    this.setState({
      txtFullName: value,
      txtFullNameValid: valid
    });

    this.setState({ 'formValid': this.isFormValid() });

  }

  handleEmailChange(event) {
    const currentValue = event.target.value;
    const valid = emailRegex.test(currentValue);


    if (valid) {
      this.setState({ 'txtEmail': currentValue, 'txtEmailValid': true });
    }
    else {
      this.setState({ 'txtEmailValid': false });
    }


  }

  isFormValid() {
    const formIsValid = this.state.txtFullNameValid && this.state.txtPhoneValid && this.state.txtEmailValid;

    return formIsValid;


  }

  handlePhone(event) {
    const currentValue = event.target.value;


    this.setState({
      'txtPhone': currentValue
    });
    if (currentValue.length === 0 || phoneRegex.test(currentValue)) {
      this.setState({ 'txtPhoneValid': true });
    }
    else {
      this.setState({ 'txtPhoneValid': false });
    }
    this.setState({ 'formValid': this.isFormValid() });
  }

  handleBlur(event) {
    const target = event.target;
    const { name, value } = target;

    this.setState({
      [name + 'Passed']: true
    });

    this.setState({ 'formValid': this.isFormValid() });

  }

  render() {
    return (
      <Layout styleClass="contact" >
        <SEO title="Contact Us" />
        <section id="sct-pg-hdr" className="container ctr-headline secondary-headline">
          <h2>Questions?<br />
            <span>We’re here to help.</span></h2>
          <p>Please use our contact format or give us a call to connect and learn more about partnering with MSM.</p>
        </section>
        <div className="ctr-main">
          <section className="ctr-form column-main">
            <div className="ctr-section-header">
              <h3>
                Ask Away!
            </h3>
              <p>
                Please use our contact format or give us a call to connect and learn more about partnering with MSM.
          </p>
            </div>
            <form name="contactUS" netlify-honeypot="bot-field" method="POST" data-netlify="true" onBlur={this.handleBlur}>
              <input type="hidden" name="form-name" value="contactUS" />
              <input type="hidden" name="bot-field" />
              <label htmlFor="txtFullName">Your Name *</label>
              <input id="txtFullName" name="txtFullName" type="text" placeholder=""
                className={`${(this.state.txtFullNamePassed && !this.state.txtFullNameValid) ? 'error' : ''}`}
                onChange={this.handleFullNameChange} value={this.state.txtfullName} onBlur={this.handleBlur} />

              <span className={`${(this.state.txtFullNamePassed && !this.state.txtFullNameValid) ? 'error' : 'error error-hidden'}`}>Your name is required.</span>

              <label htmlFor="txtEmail">Your Email *</label>
              <input id="txtEmail" className={`${(this.state.txtEmailPassed && !this.state.txtEmailValid) ? 'error' : ''}`} name="txtEmail" type="text" placeholder=""
                onChange={this.handleEmailChange} onBlur={this.handleBlur} value={this.state.txtEmail.value} />
              <span className={`${(this.state.txtEmailPassed && !this.state.txtEmailValid) ? 'error' : 'error error-hidden'}`}>Email is required.</span>

              <label htmlFor="txtEmail">Your Phone</label>
              <input id="txtPhone" name="txtPhone" type="tel"

                onChange={this.handlePhone} value={this.state.txtPhone}
                className={`${(this.state.txtPhonePassed && !this.state.txtPhoneValid) ? 'error' : ''}`}
              />
              <span className={`${(this.state.txtPhonePassed && !this.state.txtPhoneValid) ? 'error' : 'error error-hidden'}`}>Please provide a valid phone number.</span>

              <label htmlFor="txtMessage">Your Message</label>
              <textarea name="txtMessage" id="" cols="30" rows="10" defaultValue={this.state.txtMessage}></textarea>

              <button className="btn-primary" disabled={!this.state.formValid}>Send</button>
            </form>
            <div className="ctr-section-footer" style={{ 'display': 'none' }}>
              Thank you for contacting MSM. We look forward to working with you. You should expect a response within 24 hours or less.
            </div>
          </section>
          <section className="column-anc">
            <div className="contact-info">
              <h3>
                Connect with us
          </h3>
              <h4>Hours of Operation</h4>
              <p className="hours-of-operation">
                Monday - Friday: 8AM - 4PM PST
          </p>
              <p className="phone-number">
                714.571.5000
          </p>
              <p className="email-address">
                <a href="mailto:info@msmhealth.com">info@msmhealth.com</a>
              </p>
            </div>
          </section>

          <section id="sct-our-office" className="ctr-full-bleed ctr-contact-info-module">
            <div className="spacer">
            </div>
            <div className="content">

              <h3>Our Office</h3>
              <h4>Mailing Address</h4>
              <h5>Medical Specialties Managers, Inc.</h5>
              One City Boulevard West, Suite 1100
              Orange, CA 92868

          <h4>phone number</h4>
              714.571.5000

          <h4>General Inquiries Fax</h4>
              714.571.5055

        </div>
            <div className="svg">
              <img src={img1} alt="the MSM team" />
            </div>
          </section>

        </div>


      </Layout >
    )
  }
}


